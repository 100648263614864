<template>
  <div class="f_flex f_a_c f_j_sb personItem" :class="[showLine ? 'underline' : '']">
    <div class="title">
      {{title}}
    </div>
    <div class="f_flex f_a_c">
      <slot name='right'>
        <div class="val">{{val}}</div>
      </slot>
      <i v-if='showArrow' class="iconfont icon-ARROW"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    val: String,
    showArrow: Boolean,
    showLine: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
.personItem {
  padding: 14px 0;
  &:active {
    background: rgba(180, 170, 170, 0.06);
  }
  .title {
    font-size: 15px;
    color: #2D2D2D;
  }
  .val {
    font-size: 15px;
    color: #9B9B9B;
  }
}
.icon-ARROW {
  font-size: 20px;
  margin-left: 8px;
  color: #C4C4C4;
}

</style>