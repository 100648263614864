<template>
    <page :styleWrap='{background: "#F6F6F6"}'>
        <div class="personPage">
            <header-bar>
                <div slot='leading' @click="$router.back()">
                    <i style="font-size: 20px" class="iconfont icon-back"></i>
                </div>
            </header-bar>
            <div style="background: #fff; padding: 0 17px">
                <person-item title='头像' :showArrow="true">
                    <div class="avatar" slot='right'>
                        <img v-if='avatar' style="width: 100%; height: 100%" :src="avatar" alt="">
                        <input type="file" ref='avatar' @change='uploadImg' class="fileUpload">
                    </div>
                </person-item>
                <person-item
                    @click.native='$router.push("/editName")'
                    title='姓名'
                    :val='userInfo.userName ? userInfo.userName : "未设置"'>
                </person-item>
                <person-item title='手机号' :val='userInfo.phone'></person-item>
                <person-item title='公司码' :val='companyInfo.companyCode'></person-item>
                <person-item title='公司名称' :val='companyInfo.companyName'></person-item>
                <person-item v-if='userInfo.companyInfoList && userInfo.companyInfoList.length'
                             @click.native='showCode=true' title='公司二维码' :showArrow="true" :showLine="false">
                    <div class="qrcode" slot='right'>
                        <img style="width: 100%; height: 100%" src="../../assets/icon_qrcode.png" alt="">
                    </div>
                </person-item>
            </div>

            <!--      <div style="background: #fff; padding: 0 17px; margin: 10px 0" @click="$router.push('/changePwd')">-->
            <!--        <person-item title='修改登录密码' :showArrow="true" :showLine="false"></person-item>-->
            <!--      </div>-->

            <div class="logout" @click='logoutFn'>
                退出登录
            </div>
            <div class="write-off" @click='writeOff'>
                注销
            </div>
            <overlay :show='showCode' @click='showCode=false'>
                <div class="popupContent">
                    <p class="codeTip">扫一扫，加入</p>
                    <div class="codeCompany">{{ company }}</div>
                    <img class="codeImg" src="https://tva1.sinaimg.cn/large/0081Kckwly1gknvaensa5j30u00xl1ky.jpg"
                         alt="">
                </div>
            </overlay>
        </div>
    </page>
</template>

<script>
import personItem from './components/personItem.vue'
import userApi from '@/api/user'
import postApi from '@/api/post'
import {Overlay} from 'vant';
import Compressor from 'compressorjs';

export default {
    components: {
        'person-item': personItem,
        Overlay
    },
    data: () => ({
        userInfo: {},
        companyInfoList: [],
        showCode: false,
        avatar: ''
    }),
    computed: {
        companyCode () {
            const [item] = this.companyInfoList
            if (item) return item.companyCode
            return ''
        },
        companyInfo () {
            const companyInfoList = this.companyInfoList
            const companyInfo = this.$global.getItem ('companyInfo', true)
            console.log("companyInfo", companyInfo)
            if (companyInfo) {
                return companyInfo
            } else if (companyInfoList && companyInfoList.length) {
                return companyInfoList[0]
            } else {
                return '未加入'
            }
        }
    },
    methods: {
        writeOff(){
            const redirect = ()=>{
                window.localStorage.removeItem ('JSESSIONID')
                window.localStorage.removeItem ('companyInfo')
                window.localStorage.removeItem ('userInfo')
                window.sessionStorage.removeItem ('companyInfo')
                window.sessionStorage.removeItem ('userInfo')
                this.$notify ('注销成功')
                setTimeout (() => {
                    this.$router.replace ({
                        path: '/login'
                    })
                }, 200)
            }
            this.$dialog.confirm ({message: '注销账号后您的个人信息将被删除且不可恢复，确定注销吗？'}).then (() => {
             
                userApi.writeOff({}).then(()=>{
                    redirect()
                })
            }).catch (() => {
            })
            
        },
        getInfo () {
            userApi.queryUserInfo ({}).then (res => {
                if (res.data) {
                    this.userInfo = res.data.userInfo
                    this.companyInfoList = res.data.companyInfoList
                    this.avatar = res.data.userInfo.avatar
                }
            })
        },
        logoutFn () {
            this.$dialog.confirm ({message: '确定要退出登录？'}).then (() => {
                // userApi.loginOut({})
                window.localStorage.removeItem ('JSESSIONID')
                window.localStorage.removeItem ('companyInfo')
                window.localStorage.removeItem ('userInfo')
                window.sessionStorage.removeItem ('companyInfo')
                window.sessionStorage.removeItem ('userInfo')
                this.$notify ('退出登录')
                setTimeout (() => {
                    this.$router.replace ({
                        path: '/login'
                    })
                }, 200)
            }).catch (() => {
            })
        },

        uploadImg (e) {
            const self = this;
            const file = e.target.files[0]
            new Compressor (file, {
                quality: 0.76,
                success (result) {
                    const fileItem = new File ([result], file.name)
                    postApi.imgUpload ({
                        uploadFiles: fileItem,
                        bizType: 2
                    }).then (res => {
                        if (res.data) {
                            userApi.modifyInfo ({
                                userAvatar: res.data.uriList[0].uri
                            }).then (res => {
                                if (res.data && res.data.msg) {
                                    self.$toast (res.data.msg)
                                }
                            })
                        }
                    })

                    var reader = new window.FileReader ();
                    reader.readAsDataURL (result);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        self.avatar = base64data;
                    }
                },
                error (err) {
                    self.$load.hide ()
                    self.$toast (`图片上传出错了-${err.message}`)
                },
            })
            // this.uploadAvatarFn(e).then(res => {
            //   postApi.imgUpload({
            //     uploadFiles: res,
            //     bizType: 2
            //   }).then(res => {
            //     if (res.data) {
            //       userApi.modifyInfo({
            //         userAvatar: res.data.uriList[0]
            //       }).then(res => {
            //         if (res.data && res.data.msg) {
            //           this.$toast(res.data.msg)
            //         }
            //       })
            //     }
            //   })
            // })
        },
        uploadAvatarFn (e) {
            const file = e.target.files[0]
            const reader = new FileReader ()
            return new Promise ((resolve) => {
                reader.onload = ev => {
                    const data = ev.target.result
                    let img = new Image ()
                    img.src = data
                    resolve (file)
                    let extension = /^data:image\/(.*);base64/g.exec (data)[1]
                    if (/jpeg|png|gif|jpg/.test (extension)) {
                        this.avatar = data
                    } else {
                        this.$emit ('error', {msg: '非图片', code: 1})
                    }
                }
                reader.readAsDataURL (file)
            })
        },
    },
    mounted () {
        this.getInfo ()
    }
}
</script>

<style lang="less" scoped>
.personPage {
    position: relative;


    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        background: #D8D8D8;
    }

    .qrcode {
        width: 23px;
        height: 23px;
    }
    .write-off{
        color: #afaeae;
        font-size: 15px;
        line-height: 84px;
        font-weight: 500;
        text-align: center;
    }
    .logout {
        color: #D0021B;
        font-weight: 500;
        padding: 15px;
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        background: #fff;

        &:active {
            background: rgba(100, 100, 100, 0.04);
        }
    }

    .popupContent {
        border-radius: 8px;
        background: #fff;
        padding: 22px 30px;
        margin: 28px;
        margin-top: 180px;
        text-align: center;
        box-sizing: border-box;

        .codeImg {
            width: 258px;
            height: 258px;
        }

        .codeTip {
            font-size: 13px;
            color: #4a4a4a;
            margin-bottom: 9px;
        }

        .codeCompany {
            font-size: 16px;
            color: #2d2d2d;
            margin-bottom: 21px;
        }
    }
}

.fileUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
</style>
